<template>
    <div class="px-0">

        <!-- header & profile summary -->
        <span class="header-page">
            <span class="title-header text-h4 pt-5 pl-5">
                User Management
            </span>

            <v-spacer></v-spacer>

            <v-btn
            class="ma-5 primary--text"
            color="white"
            elevation="2"
            @click="backToLandingPage"
            >
            BACK
            </v-btn>
        </span>
    
        <div class="px-5 mb-7">
        <v-card title class="mt-5">
            <v-tabs v-model="tabParent" show-arrows>
                <v-tab v-for="item in listTabParent" :key="item">
                    {{ item }}
                </v-tab>
            </v-tabs>

            <v-tabs-items v-model="tabParent">


                <!-- Content Existing User List -->
                <v-tab-item>
                    <content-UserList></content-UserList>
                </v-tab-item>

                <!-- Content Request User -->
                <!-- <v-tab-item>
                    <content-UserRequest></content-UserRequest>
                </v-tab-item> -->


            </v-tabs-items>
        </v-card>
        </div>

    </div>
</template>

<script>
import axios from 'axios';

import contentUserList from '@/components/Admin/UserList'
// import contentUserRequest from '@/components/Admin/UserRequest'

export default {
    components: {
        'content-UserList': contentUserList,
        // 'content-UserRequest': contentUserRequest,
    },

    data: () => ({

        //Tab
        tabParent: null,
        listTabParent: [
            "User List",
            // "User Request",

        ],

        idleornot:  null

    }),

    mounted() {

        this.idleornot_listener();

    },

    methods: {


        /////////////////spa stuff downhere
        /////////idle or not cek start here////////////

        idleornot_startcek()
        {
            //console.log('ada event');
            //clearInterval(this.idleornot);
            clearInterval(this.idleornot);
            this.idleornot = null;

            this.idleornot = setInterval(() => {
                this.idlefortoolong()
                }, 5 * 60 * 1000);

        },

        idlefortoolong()
        {

            alert('You will be logged out due to inactivity');
            this.$store.dispatch("signOut");
        },


        idleornot_listener()
        {


            this.$el.addEventListener('click', this.idleornot_startcek);
            this.$el.addEventListener('scroll', this.idleornot_startcek);
            this.$el.addEventListener('mousemove', this.idleornot_startcek);
            this.$el.addEventListener('keydown', this.idleornot_startcek);
            this.$el.addEventListener('keypress', this.idleornot_startcek); //outdated

        }

        /////////end of idle or not cek start here////////////



    },

};
</script>

<style lang="scss">
@import '@/assets/scss/main.scss';
</style>


